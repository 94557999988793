





































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import LoadingOverlay from '@/app/ui/components/LoadingOverlay/index.vue'
import controller, {
  HistoryLogUserParamsInterface,
} from '@/app/ui/controllers/AdjustmentParcelPoinController'
import AdjustmentTextInput from '../../components/AdjustmentTextInput/index.vue'
import RadioButton from '@/app/ui/components/RadioButton/index.vue'
import {
  ALL_DATA_ADJUSTMENT_PAGINATION,
  EnumTransactionParcelPoinRequest,
  Utils,
} from '@/app/infrastructures/misc'
import { DataObject } from '@/app/ui/components/DataTableV2/type'
import ModalDetail from '../../components/AdjustmentModals/ModalDetail/index.vue'
import DataTableV2 from '@/app/ui/components/DataTableV2/index.vue'
import PaginationNav from '@/app/ui/components/PaginationNav/index.vue'
import { Dictionary } from 'vue-router/types/router'

/**
 * interface for form state
 */
export interface formPoin {
  poin: number | null
  note: string
  transaction: EnumTransactionParcelPoinRequest
}

@Component({
  components: {
    LoadingOverlay,
    AdjustmentTextInput,
    RadioButton,
    ModalDetail,
    DataTableV2,
    PaginationNav,
  },
})
export default class DetailAllDataAdjustmentPoin extends Vue {
  controller = controller
  form: formPoin = {
    poin: null,
    note: '',
    transaction: EnumTransactionParcelPoinRequest.CREDIT,
  }
  EnumTransactionParcelPoinRequest = EnumTransactionParcelPoinRequest
  headers: Array<Record<string, string | Record<string, string>>> = [
    {
      title: 'Date',
      customStyle: { minWidth: '220px', maxWidth: '220px' },
    },
    {
      title: 'Request Poin',
      customStyle: { minWidth: '125px', maxWidth: '125px' },
    },
    {
      title: 'Requestor',
      customStyle: { minWidth: '140px', maxWidth: '140px' },
    },
    {
      title: 'Approval',
      customStyle: { minWidth: '140px', maxWidth: '140px' },
    },
    {
      title: 'Notes',
      customStyle: { minWidth: '155px', maxWidth: '155px' },
    },
    {
      title: 'Status',
      customStyle: { minWidth: '110px', maxWidth: '110px' },
    },
  ]
  parameters = {
    sortBy: 'NEWEST',
    status: 'SHOWALL',
    page: 1,
    perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
    customerId: 0,
  }
  isShowTable = true
  modalDetail = false
  currentNotes = ''

  created(): void {
    if (this.$route.query instanceof Object) {
      const queries = <Record<string, never>>this.$route.query
      this.parameters = {
        page: Utils.alwaysNumber(queries.page || 1),
        perPage: ALL_DATA_ADJUSTMENT_PAGINATION,
        sortBy: 'NEWEST',
        status: 'SHOWALL',
        customerId: 0,
      }
    }

    this.fetchHistory()
    this.fetchDetail()
  }

  get dataHistoryUser(): Array<DataObject[]> {
    if (controller.listHistoryLogPoinUser.length > 0) {
      return this.controller.listHistoryLogPoinUser.map(item => {
        return [
          {
            value: item.updatedAt ? this.getDate(item.updatedAt) : item.createdAt ? this.getDate(item.createdAt) : '',
            customStyle: { minWidth: '220px', maxWidth: '220px' },
          },
          {
            value: `${
              Number(item.delta) > 0
                ? `+${Utils.currencyDigit(Number(item.delta))}`
                : Utils.currencyDigit(Number(item.delta))
            }`,
            customStyle: { minWidth: '125px', maxWidth: '125px' },
          },
          {
            value: item?.requester || '-',
            customStyle: { minWidth: '140px', maxWidth: '140px' },
          },
          {
            value: item?.approval || '-',
            customStyle: { minWidth: '140px', maxWidth: '140px' },
          },
          {
            value: item?.note || '-',
            customStyle: { minWidth: '155px', maxWidth: '155px' },
          },
          {
            value: item?.status || '-',
            customStyle: { minWidth: '110px', maxWidth: '110px' },
          },
        ]
      })
    }
    return []
  }

  private getDate(date: string): string {
    return Utils.formatTimeZone(
      Utils.formatDate(date, 'DD MMM YYYY, HH:mm:ss Z')
    )
  }

  get params(): HistoryLogUserParamsInterface {
    return {
      ...this.parameters,
      customerId: Number(this.$route.params.id.split('-')[0]),
    }
  }

  /**
   * get phone number for history log poin user
   */
  get phoneNumberUser(): string {

    if (this.controller.detailAdjustmentParcelPoin.phoneNumber) {
      return Utils.countryIndonesiaPhoneNumber(this.controller.detailAdjustmentParcelPoin.phoneNumber, true)
    } else if (this.controller.listHistoryLogPoinUser[0]?.phoneNumber) {
      return Utils.countryIndonesiaPhoneNumber(this.controller.listHistoryLogPoinUser[0]?.phoneNumber, true)
    }

    return ''
  }

  /**
   * get detail adjustment
   */
  private fetchDetail(): void {
    this.controller.getDetailAdjustmentParcelPoin(
      Number(this.$route.params.id.split('-')[1])
    )
  }

  /**
   * fetch history adjustment
   */
  private fetchHistory(): void {
    this.controller.getHistoryLogPoinUser(this.params)
  }

  /**
   * function toggle isShowTable
   */
  private toggleTableShow(): void {
    this.isShowTable = !this.isShowTable
  }

  /**
   * function to see note when click view detail
   */
  private seeNotes(index: number): void {
    this.modalDetail = true
    this.currentNotes = <string>this.dataHistoryUser[index][4].value
  }

  /**
   * when detail from controller changed, change form
   */
  @Watch('controller.detailAdjustmentParcelPoin', { deep: true })
  onChangedForm(): void {
    const poin = this.controller.detailAdjustmentParcelPoin.delta || 0
    const transactionStatus =
      poin > 0
        ? EnumTransactionParcelPoinRequest.CREDIT
        : EnumTransactionParcelPoinRequest.DEBIT

    this.form = {
      poin: this.controller.detailAdjustmentParcelPoin.delta || null,
      transaction: transactionStatus,
      note: this.controller.detailAdjustmentParcelPoin.note || '',
    }
  }

  @Watch('params')
  onParamsChanged(val: Dictionary<string | (string | null)[] | null | undefined> | undefined): void {
    this.$router
      .replace({
        query: val,
      })
      .catch(() => false)
  }
}
